<template>
  <div id="app">
  <div id="discription">
  <p> Вставь сюда изображение чтобы получить EXIF информацию о нем </p>
  </div>
  <div id="image_putter">
  </div>
  </div>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
#app {
  font-family: "Avenir", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
</style>
